






import { Route, RawLocation }          from 'vue-router'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { projectModule, stateModule }  from '@/store'

import ProposalDetail from '@/components/proposal/detail/Index.vue'

import { ProposalResource } from '@/models/proposals/ProposalResource'
import { ProcessResource }  from '@/models/process/ProcessResource'
import { ProjectResource }  from '@/models/projects/ProjectResource'

@Component({
  components: {
    ProposalDetail
  }
})
export default class ProcessProposalDetail extends Vue {

  @Prop()
  private process!: ProcessResource

  private proposal: ProposalResource | null = null

  // Route hooks
  private async created(): Promise<void> {
    this.updateProposalsData();

    this.$root.$on('updateProposals', () => {
      console.log('Updated emitter');
      
      this.updateProposalsData()
    })
  }

  private async updateProposalsData() {
    stateModule.setLoading(true)
    try {
      const { data } = await this.process.getProposal(parseInt(this.$route.params.proposal_id, 10))
      this.proposal = data
    } finally {
      stateModule.setLoading(false)
    }
  }

  @Watch('$route.params.proposal_id')
  private async onProposalChange(id: string, oldId?: string): Promise<void> {
    if (parseInt(id, 10) !== (this.proposal ? this.proposal.id : 0)) {
      stateModule.setLoading(true)
      try {
        const { data } = await this.process.getProposal(parseInt(id, 10))
        this.$set(this, 'proposal', data)
      } finally {
        stateModule.setLoading(false)
      }
    }
  }

  private get backRoute(): RawLocation {
    const params = { ...this.$route.params }
    delete params.proposal_id
    return { name: 'projects-detail-process', params }
  }

}
